<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="zillaparishadlist" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="maz1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Zilla Parishad</h5>
                        </template>
                        <template v-slot:right>
                            <!-- <Button label="Import" icon="pi pi-paperclip" class="p-mr-2"
                                @click="onImportZillaParishad" /> -->
                            <Button label="Add Zilla Parishad" icon="pi pi-plus" class=" p-mr-2"
                                @click="addZillaParishadDialogOpen" />
                                <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter" @click="filterlisting()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success" @click="filterlisting()"></Button>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Zilla Parishad Detail" headerStyle="width: 38%">
                        <template #body="{data}">
                            <div>
                                <div class="p-text-capitalize">{{ data.maz10 ? data.maz10 : 'N/A' }} <span v-if="data.maz9">({{data.maz9}})</span></div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Vidhan Sabha Name" headerStyle="width: 20%">
                        <template #body="{data}">
                            <div class="p-text-capitalize">{{ data.maz4 ? data.maz4 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="State" headerStyle="width: 14%">
                        <template #body="{data}">
                            <div class="p-text-capitalize">{{ data.maz6 ? data.maz6 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="District" headerStyle="width: 14%">
                        <template #body="{data}">
                            <div class="p-text-capitalize">{{ data.maz8 ? data.maz8 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">
                        <template #body="{data}">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editZillaParishadDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add zilla parishad dialog start here -->
    <Dialog v-model:visible="addZillaParishadStatus" :style="{ width: '800px' }" header="Add Zilla Parishad"
        :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="stateList" @change="getdistrictName($event)" optionLabel="label"
                        placeholder="Select ..." autofocus :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{
                    v$.state.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="district">
                        District
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="district" v-model="district" @change="getvishansabhaName($event)" :options="districtList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.district.$error">{{
                    v$.district.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="vidhansabha">
                        Vidhan Sabha
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="vidhansabha" v-model="vidhansabha" :options="vidhansabhaList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.vidhansabha.$error">{{
                            v$.vidhansabha.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="zillaparishadname">
                        Zilla Parishad Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="zillaparishadname" class="p-text-capitalize" v-model.trim="zillaparishadname"
                        required="true" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !zillaparishadname }" />
                    <small class="p-invalid p-error" v-if="v$.zillaparishadname.$error">{{
                            v$.zillaparishadname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="zillaparishadnumber">
                        Zilla Parishad Number
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="zillaparishadnumber" class="p-text-capitalize" v-model.trim="zillaparishadnumber"
                        required="true" @keypress="onlyNumber" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !zillaparishadnumber }" />
                    <small class="p-invalid p-error" v-if="v$.zillaparishadnumber.$error">{{
                            v$.zillaparishadnumber.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="reservationvalue">
                        Reservation Seats
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="reservationvalue" v-model="reservationvalue" :options="reservationList" optionLabel="label"
                        placeholder="Select ..." :filter="false" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.reservationvalue.$error">{{
                            v$.reservationvalue.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addZillaParishad" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add zilla parishad dialog end here -->
    <!-- edit zilla parishad dialog start here -->
    <Dialog v-model:visible="editZillaParishadStatus" :style="{ width: '800px' }" header="Edit Zilla Parishad"
        :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="stateList" @change="getdistrictName($event)" optionLabel="label"
                        placeholder="Select ..." autofocus :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{
                    v$.state.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="district">
                        District
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="district" v-model="district" @change="getvishansabhaName($event)" :options="districtList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.district.$error">{{
                    v$.district.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="vidhansabha">
                        Vidhan Sabha
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="vidhansabha" v-model="vidhansabha" :options="vidhansabhaList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.vidhansabha.$error">{{
                            v$.vidhansabha.$errors[0].$message
                    }}</small>
                </div>
                
                <div class="p-field p-col-12 p-md-6">
                    <label for="zillaparishadname">
                        Zilla Parishad Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="zillaparishadname" class="p-text-capitalize" v-model.trim="zillaparishadname"
                        required="true" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !zillaparishadname }" />
                    <small class="p-invalid p-error" v-if="v$.zillaparishadname.$error">{{
                            v$.zillaparishadname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="zillaparishadnumber">
                        Zilla Parishad Number
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="zillaparishadnumber" class="p-text-capitalize" v-model.trim="zillaparishadnumber"
                        required="true" @keypress="onlyNumber" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !zillaparishadnumber }" />
                    <small class="p-invalid p-error" v-if="v$.zillaparishadnumber.$error">{{
                            v$.zillaparishadnumber.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="reservationvalue">
                        Reservation Seats
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="reservationvalue" v-model="reservationvalue" :options="reservationList" optionLabel="label"
                        placeholder="Select ..." :filter="false" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.reservationvalue.$error">{{
                            v$.reservationvalue.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateZillaParishad" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit zilla parishad dialog end here -->
    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterListingDialog" :style="{ width: '800px' }" header="Filter" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">State</label>
                    <MultiSelect v-model="state_fks" :options="stateList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="loksabhaname">District</label>
                    <MultiSelect v-model="district_fks" :options="districtList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="zillaparishadname">Zilla Parishad</label>
                    <MultiSelect v-model="zillaparishad_fks" :options="zillaparishadList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="vidhansabhaname">Vidhan Sabha</label>
                    <MultiSelect v-model="vidhansabha_fks" :options="vidhansabhaList" optionValue="value"
                        optionLabel="label" placeholder="Select" :filter="true" class="multiselect-custom"
                        appendTo="body"></MultiSelect>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(state_fks, zillaparishad_fks, district_fks, vidhansabha_fks)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->

    <!-- import csv dialog start here -->
    <Dialog v-model:visible="isImportZillaParishad" :style="{ width: '1100px' }" header="Import Zilla Parishad" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6" align="left">
                    <FileUpload mode="basic" name="demo[]" url="./upload" accept="text/csv" :maxFileSize="1000000" @upload="onUpload" />
                </div>
                <div class="p-field p-col-12 p-md-6" align="right">
                        <label class="p-mr-2">
                            Download Sample Sheet:
                        </label>
                        <!-- TODO: Need CSV File URL here -->
                        <router-link href="replace-csv-string" target="_blank" to="#">
                            <Button icon="pi pi-download" v-tooltip.left="'Download Sample Sheet'" class="p-button-rounded">
                            </Button>
                        </router-link>
                </div>
                <div class="p-field p-col-12">
                    <h5 class="indigo-color p-m-0 p-pl-1">Note:</h5>
                    <ol style="line-height: 1.5;">
                        <li>The file name should be same as sample file name "import_open_enquiries.csv".</li>
                        <li>Do not just change any files extension from .XLS or any other extension to CSV and upload it.</li>
                        <li>You can upload enquiries in one sheet up to 5000 enquiries in one attempt.</li>
                        <li>Don't change and delete any column name in CSV sheet.</li>
                        <li>Date format must be in "dd-mm-yyyy".</li>
                        <li>Insert employee mobile number properly to allocate the enquiry to a particular employee, else enquiry will be inserted into logged-in employees Lok-neta account.</li>
                        <li>Project name, unit type & source must be the same as in the Lok-neta System.</li>
                        <li>In CSV sheet enquiry date, first name or last name, mobile or email, employee mobile, source column's are mandatory.</li>
                        <li>Submitted sheets for importing enquiries till 10 pm will be executed on the same night and the result will be available by 7 am next day</li>
                    </ol>
                </div>
            </div>
        </div>
        <template #footer>
                <Button label="Submit" icon="pi pi-check" class="p-button-text"
                @click="onSubmit" />
        </template>
    </Dialog>
    <!-- import dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers, numeric } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            zillaparishadlist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addZillaParishadStatus: false,
            editZillaParishadStatus: false,
            state: '',
            stateList: [],
            district: '',
            districtList: [],
            vidhansabha: '',
            vidhansabhaList: [],
            zillaparishadname: '',
            zillaparishadnumber: '',
            reservationvalue: '',
            reservationList: [],
            showLoader: false,
            submitted: false,
            row_id: null,
            filterListingDialog: false,
            state_fks: [],
            zillaparishad_fks: [],
            district_fks: [],
            zillaparishadList: [],
            vidhansabha_fks: [],
            constitencytype_fk: [],
            showFillFilter: false,
            isImportZillaParishad: false,
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getReservations();
        this.getzillaparishadlist();
        // this.getvidhansabha();
        this.getStates();
    },
    validations() {
        return {
            state: { required: helpers.withMessage('Please select state', required) },
            district: { required: helpers.withMessage('Please select district', required) },
            vidhansabha: { required: helpers.withMessage('Please select vidhan sabha', required) },
            zillaparishadname: { required: helpers.withMessage('Please enter zilla parishad name', required) },
            zillaparishadnumber: { required: helpers.withMessage('Please enter zilla parishad number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric) },
            reservationvalue: { required: helpers.withMessage('Please select reservation', required) },
        };
    },
    methods: {
        onImportZillaParishad(){
            this.isImportZillaParishad = true;
        },
        getdistrictName(e) {
            this.vidhansabha = '';
            this.zillaparishadname = '';
            this.zillaparishadnumber = '';
            if (e.value) {
                this.ApiService.getdistrict({ state_id: e.value.value }).then((items) => {
                    if (items.success === true) {
                        this.districtList = items.data;
                    }
                });
            }
        },
        getvishansabhaName(e) {
            this.zillaparishadname = '';
            this.zillaparishadnumber = '';
            if (e.value) {
                this.ApiService.getvidhansabha({ district_id: e.value.value }).then((items) => {
                    if (items.success === true) {
                        this.vidhansabhaList = items.data;
                    }
                });
            }
        },
        getStates(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        // getvidhansabha(ev) {
        //     this.ApiService.getvidhansabha(ev).then((data) => {
        //         if (data.status == 200) {
        //             this.vidhansabhaList = data.data;
        //         } else {
        //             this.vidhansabhaList = '';
        //         }
        //     });
        // },
        getReservations(ev) {
            this.ApiService.getReservations(ev).then((data) => {
                if (data.status == 200) {
                    this.reservationList = data.data;
                } else {
                    this.reservationList = '';
                }
            });
        },
        getzillaparishadlist(ev) {
            this.loading = true;
            this.ApiService.getzillaparishadlist(ev).then((data) => {
                if (data.status == 200) {
                    this.zillaparishadlist = data.data.result;
                    this.totalRecords = data.data.count;
                } else {
                    this.zillaparishadlist = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getzillaparishadlist({ 
                page_no: event.page,
                statefilter: this.event_state,
                zillaparishadfilter: this.event_zillaparishad,
                districtfilter: this.event_district,
                vidhansabhafilter: this.event_vidhansabha
             });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        addZillaParishadDialogOpen() {
            this.addZillaParishadStatus = true;
            this.submitted = false;
            this.state = '';
            this.district = '';
            this.vidhansabha = '';
            this.zillaparishadname = '';
            this.zillaparishadnumber = '';
            this.reservationvalue = '';
        },
        editZillaParishadDialogOpen(e) {
            this.getzillaparishadlist();
            this.editZillaParishadStatus = true;
            this.submitted = false;
            this.row_id = e.maz1;
            let maz5 = this.stateList.filter(function (item) {
                return item.value == e.maz5;
            });
            if (maz5.length > 0) {
                this.state = maz5[0];
            }
            let maz7 = this.districtList.filter(function (item) {
                return item.value == e.maz7;
            });
            if (maz7.length > 0) {
                this.district = maz7[0];
            }
            let maz3 = this.vidhansabhaList.filter(function (item) {
                return item.value == e.maz3;
            });
            if (maz3.length > 0) {
                this.vidhansabha = maz3[0];
            }
            this.zillaparishadname = e.maz10;
            this.zillaparishadnumber = e.maz9;
            let maz11 = this.reservationList.filter(function (item) {
                return item.value == e.maz11;
            });
            if (maz11.length > 0) {
                this.reservationvalue = maz11[0];
            }
        },
        addZillaParishad() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['maz5'] = this.state.value;
            fields['maz6'] = this.state.label;
            fields['maz7'] = this.district.value;
            fields['maz8'] = this.district.label;
            fields['maz3'] = this.vidhansabha.value;
            fields['maz4'] = this.vidhansabha.label;
            fields['maz10'] = this.zillaparishadname;
            fields['maz9'] = this.zillaparishadnumber;
            fields['maz11'] = this.reservationvalue.value;
            fields['maz12'] = this.reservationvalue.label;

            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.createzillaparishad(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.addZillaParishadStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.state = '';
                        this.district = '';
                        this.vidhansabha = '';
                        this.zillaparishadname = '';
                        this.zillaparishadnumber = '';
                        this.reservationvalue = '';
                        this.getzillaparishadlist();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        updateZillaParishad() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['maz1'] = this.row_id;
            fields['maz5'] = this.state.value;
            fields['maz6'] = this.state.label;
            fields['maz7'] = this.district.value;
            fields['maz8'] = this.district.label;
            fields['maz3'] = this.vidhansabha.value;
            fields['maz4'] = this.vidhansabha.label;
            fields['maz10'] = this.zillaparishadname;
            fields['maz9'] = this.zillaparishadnumber;
            fields['maz11'] = this.reservationvalue.value;
            fields['maz12'] = this.reservationvalue.label;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.createzillaparishad(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.editZillaParishadStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getzillaparishadlist();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        filterlisting() {
            this.filterListingDialog = true;
            this.getVidhansabhaFilter();
            this.getStatesFilter();
            this.getDistrictFilter();
            this.getZillaparishadFilter();
        },
        getFilteredData(event_state, event_zillaparishad, event_district, event_vidhansabha) {
            this.loading = true;
            this.state_fks = event_state;
            this.zillaparishad_fks = event_zillaparishad;
            this.district_fks = event_district;
            this.vidhansabha_fks = event_vidhansabha;
            this.getzillaparishadlist({
                statefilter: this.state_fks,
                zillaparishadfilter: this.zillaparishad_fks,
                districtfilter: this.district_fks,
                vidhansabhafilter: this.vidhansabha_fks,
            });
            this.showFillFilter = true;
            this.filterListingDialog = false;
        },
        reSetFilteredData() {
            this.state_fks = "";
            this.zillaparishad_fks = '';
            this.district_fks = '';
            this.vidhansabha_fks = '';
            this.getzillaparishadlist();
            this.showFillFilter = false;
            this.filterListingDialog = false;
        },
        getVidhansabhaFilter(ev) {
            this.ApiService.getvidhansabha(ev).then((data) => {
                if (data.status == 200) {
                    this.vidhansabhaList = data.data;
                } else {
                    this.vidhansabhaList = '';
                }
            });
        },
        getStatesFilter(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        getDistrictFilter(ev) {
            this.ApiService.getdistrict(ev).then((items) => {
                if (items.success === true) {
                    this.districtList = items.data;
                } else {
                    this.districtList = '';
                }
            });
        },
        getZillaparishadFilter(ev) {
            this.ApiService.getzillaparishad(ev).then((items) => {
                if (items.success === true) {
                    this.zillaparishadList = items.data;
                } else {
                    this.zillaparishadList = '';
                }
            });
        },
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
